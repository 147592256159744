import React from 'react';
import './Services.scss'

type Service = {
    serviceId: string
    label: string
    [key: string]: string
}

interface ServicesProps {
    servicesList: Service[]
    selectedServices: string[]
    setSelectedServices: React.Dispatch<React.SetStateAction<string[]>>
}

// const servicesList = [
//     'Beauty',
//     'Coding',
//     'Automation Testing',
//     'QA',
//     'Manual Testing',
//     'Frontend',
//     'Backend',
//     'Tester',
//     'Documentation',
//     'Code Review',
//     'Code Quality',
//     'Integration',
//     'CI/CD',
//     'Technical'
// ];

const Services = ({servicesList, selectedServices, setSelectedServices}: ServicesProps) => {

    const handleOnServiceSelect = (service: string) => {
        setSelectedServices((prevList) => {
            const newArray = [...prevList];
            if(newArray?.includes(service)) {
                return newArray?.filter(item => item !== service);
            } else {
                newArray.push(service);
                return newArray
            }
        });
    }

    return (
       <div className='Services'>
        {servicesList?.map((service: Service) => {
            return <div className={`Services__Tile Services__Tile__${selectedServices.includes(service?.serviceId) ? 'Selected': 'NotSelected'}`} key={service?.serviceId} onClick={() => handleOnServiceSelect(service?.serviceId)}>{service?.label}</div>
        })}
       </div>
    );
}

export default Services;