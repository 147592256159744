import React from 'react';
import { Input, InputNumber, Typography } from 'antd';
import './InputBox.scss'

interface InputProps {
    className?: string
    label?: string
    type?: string
    value?: string
    variant?: 'outlined'|'borderless'|'filled'
    width?: string
    placeholder?: string
    prefix?: React.ReactNode | null
    suffix?: React.ReactNode | null
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
    onNumberInputChange?: (value: string | null) => void
    status?: 'error'|'warning'|''
    errorMessage?: string
}

const { Title, Text } = Typography;


const InputBox = ({
    className = '',
    label = '',
    type = 'text',
    value = '',
    variant = 'filled',
    width = '100%',
    placeholder = '',
    prefix = null,
    suffix = null,
    status = '',
    errorMessage = '',
    onChange = () => {},
    onNumberInputChange = () => {}
}: InputProps) => {

    return (
        <div className={`${className} Input__Container`}>
            {label && <Title className='Input__Container__Label' level={5}>{label}</Title>}
            {type === 'number' ? (
                <InputNumber status={status} prefix={prefix} suffix={suffix} value={value} variant={variant} placeholder={placeholder} style={{width: `${width}`}} onChange={onNumberInputChange} />
            ) : type === 'password' ? (
                <Input.Password status={status} className='Input__Container__InputBox' prefix={prefix} suffix={suffix} type={type} value={value} variant={variant} onChange={onChange} placeholder={placeholder} style={{width: `${width}`}} />
            ) : (
                <Input status={status} className='Input__Container__InputBox' prefix={prefix} suffix={suffix} type={type} value={value} variant={variant} onChange={onChange} placeholder={placeholder} style={{width: `${width}`}}/>
            )}
            {['error', 'warning'].includes(status) && <Text type='danger'>{errorMessage}</Text>}
        </div>
    );
}

export default InputBox;