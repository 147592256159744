import React, { useEffect, useState } from 'react';
import CustomButton from '../../components/CustomButton';
import CONSTANTS from './constants';
import './Payment.scss'
import PaymentDetails from '../Registration/Common/PaymentDetails';
import { Link } from 'react-router-dom';


const Payment = () => {

    const { TITLES, NEXT, ALREADY_HAVE_ACCOUNT, GOTO_DASHBOARD } = CONSTANTS;
    const [currentScreen, setCurrentScreen] = useState<string>('paymentDetails');
    const [currentScreenIndex, setCurrentScreenIndex] = useState<number>(0);

    useEffect(() => {
        switch(currentScreenIndex) {
            case 0:
                setCurrentScreen('paymentDetails');
                break;
            case 1:
                // logic
                break;
            default:
                setCurrentScreen('paymentDetails');
        }

    }, [currentScreenIndex]);

    return (
        <>
            <div className='Registration__Card__Body__MainContent'>
                <div className='Registration__Card__Body__MainContent__SubHeader'>
                    {currentScreen === 'profilePicUploader' && <>{TITLES.PAYMENT_DETAILS_TITLE}</>}
                </div>
                <div className='Registration__Card__Body__MainContent__Body'>
                    {currentScreen === 'paymentDetails' && (<PaymentDetails />)}
                </div>
                <div className='Registration__Card__Body__MainContent__Footer'>
                    <CustomButton className='Registration__Card__Body__MainContent__Footer__NextBtn' onClick={(event) => setCurrentScreenIndex(currentScreenIndex + 1)} buttonText={NEXT}  />
                </div>
            </div>
            <div className='Registration__Card__Body__Footer'>{ALREADY_HAVE_ACCOUNT} <Link to={'/login'} className='Registration__Card__Body__Footer__GotoDashboard'>{GOTO_DASHBOARD}</Link></div>
        </>
    );
}

export default Payment;