import React from 'react';
import { Typography } from 'antd';
import star from '../../assets/star.svg';
import './FreelancerOverview.scss';
import CustomButton from '../../components/CustomButton';
import { useAppSelector } from '../../hooks/reduxHooks';
import project1 from '../../assets/project1.png';
import project2 from '../../assets/project2.png';
import ProjectCard from '../../components/ProjectCard';

const { Title, Text } = Typography;

interface IJobOverviewProps {
    isAccepting?: boolean
    onClose: () => void
    onAccept: (applicationId: string) => void
}

const projectsData = [
    {
        id: '1',
        title: 'Essence of happiness',
        imageUrl: project1
    },
    {
        id: '2',
        title: 'Urban Symphony',
        imageUrl: project2
    },
    {
        id: '3',
        title: 'Essence of happiness',
        imageUrl: project1
    },
    {
        id: '4',
        title: 'Urban Symphony',
        imageUrl: project2
    },
    {
        id: '5',
        title: 'Essence of happiness',
        imageUrl: project1
    }
]

const FreelancerOverview = ({isAccepting = false, onClose, onAccept}: IJobOverviewProps) => {
    const selectedApplication = useAppSelector(state => state?.client?.selectedApplication);

    return (
        <div className='FreelancerOverviewContainer'>
            <div className='FreelancerOverviewContainer__FirstRow'>
                <img className='FreelancerOverviewContainer__FirstRow__Image' src={selectedApplication?.freelancer?.profileImageUrl} alt={selectedApplication?.freelancer?.firstName} />
                <div className='FreelancerOverviewContainer__FirstRow__Details'>
                    <div className='FreelancerOverviewContainer__FirstRow__Details__Name'>
                        <Title level={4} style={{marginBottom: "0px"}}>{selectedApplication?.selectedApplication?.freelancer?.firstName} {selectedApplication?.freelancer?.lastName}</Title>
                        <div className='FreelancerOverviewContainer__FirstRow__Details__Name__Rating'>
                            <img src={star} alt='rating' />
                            <>{selectedApplication?.freelancer?.rating}</>
                        </div>
                    </div>
                    <div className='FreelancerOverviewContainer__FirstRow__Details__ProfessionalTitle'>{selectedApplication?.freelancer?.professionalTitle}</div>
                </div>
            </div>
            <div className='FreelancerOverviewContainer__SecondRow'>
                Location: {selectedApplication?.freelancer?.location}
            </div>
            <div className='FreelancerOverviewContainer__ThirdRow'>
                <Title level={5}>Message from the freelancer</Title>
                <Text>{selectedApplication?.description}</Text>
            </div>
            <div className='FreelancerOverviewContainer__FourthRow'>
                <Title level={5}>Calendar link to set up a call hassle-free</Title>
                <Text>{selectedApplication?.meetUrl}</Text>
            </div>
            <div className='FreelancerOverviewContainer__FifthRow'>
                <Title level={5}>Example projects</Title>
                <div className='FreelancerOverviewContainer__FifthRow__LinkedProjects'>
                        {selectedApplication?.projects?.map((projectId: string) => {
                            const projectInfo = projectsData?.find(project => project?.id === projectId);
                            return <ProjectCard key={projectInfo?.id} id={projectInfo?.id || ''} title={projectInfo?.title || ''} imageUrl={projectInfo?.imageUrl || ''} />
                    })}
                </div>
            </div>
            <div className='FreelancerOverviewContainer__SixthRow'>
                <CustomButton buttonText='Cancel' onClick={onClose} />
                <CustomButton buttonText='Accept Application' onClick={() => onAccept(selectedApplication?.applicationId)} loading={isAccepting} />
            </div>
        </div>
    );
};

export default FreelancerOverview;