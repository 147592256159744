import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

const axiosInstance = axios.create({
    baseURL: 'https://dev-api.mercurygigs.com/',
    headers: {
        ...axios.defaults.headers,
        'Content-Type': 'application/json',
      },
});

axiosInstance.interceptors.request.use((request) => {
  const sessionToken = JSON.parse(sessionStorage.getItem('token') || 'null');
  const userInfo = JSON.parse(localStorage.getItem('userDetails') || '{}');
  request.headers.Authorization = sessionToken ? sessionToken : userInfo?.accessToken  ? userInfo?.accessToken :  null;

  return request;
})

// axiosInstance.interceptors.response.use(
//     (response) => response,
//     (error) => {
//     //   if (error.response && error.response.status === 401) {
//     //     console.log('call the refresh token api here')
//     //     // Handle 401 error, e.g., redirect to login or refresh token
//     //   }
//       return Promise.reject(error)
//     },
// );

export default axiosInstance;