import React from 'react';
import { Typography } from 'antd';
import InputBox from '../../../../components/InputBox';
import './PasswordSetup.scss'
import CONSTANTS from './constants';

type PasswordInputsProps = {
    password: string
    confirmPassword: string
    showMismatchError: boolean
}

interface PasswordSetupProps {
   passwordInputs: PasswordInputsProps
   handleOnPasswordChange: (key: string, value: string) => void
}

const { Text } = Typography;

const PasswordSetup = ({passwordInputs, handleOnPasswordChange}: PasswordSetupProps) => {

    return (
        <div className='SetupPassword__Container'>
            <InputBox className='SetupPassword__Container__Password' type='password' label={CONSTANTS.PASSWORD_LABEL} value={passwordInputs.password} variant='filled' placeholder={CONSTANTS.PASSWORD_PLACEHOLDER} onChange={(event) => handleOnPasswordChange('password', event?.target?.value)} />
            <InputBox className='SetupPassword__Container__ConfirmPassword' type='password' label={CONSTANTS.CONFIRM_PASSWORD_LABEL} value={passwordInputs.confirmPassword} variant='filled' placeholder={CONSTANTS.CONFIRM_PASSWORD_PLACEHOLDER}  onChange={(event) => handleOnPasswordChange('confirmPassword', event?.target?.value)}/>
            {(passwordInputs?.showMismatchError) && <Text type={'danger'}>Not matching</Text>}
        </div>
    );
}

export default PasswordSetup;