import React, { useState } from 'react';
import { Input, Typography, Upload } from 'antd';
import './EditProfile.scss'
import InputBox from '../../../../components/InputBox';
import CustomButton from '../../../../components/CustomButton';
import CONSTANTS from './constants';
import { useAppDispatch, useAppSelector } from '../../../../hooks/reduxHooks';
import type { UploadProps } from 'antd';
import { updateClientProfile } from '../../../../services/clientService';
import { useNavigate } from 'react-router-dom';
import { setCredentials } from '../../../../slice/authSlice';
import { useAuth } from '../../../../contexts/AuthContext';

interface EditProfileProps {
    imageUrl?: string
    firstName?: string
    lastName?: string
    setIsEditProfile: React.Dispatch<React.SetStateAction<boolean>>
}

const { Title } = Typography;
const { TextArea } = Input;


const EditProfile = ({setIsEditProfile}: EditProfileProps) => {

    const dispatch = useAppDispatch();
    const { token, user: loggedInUser, saveLoggedInUser } = useAuth();
    const user = useAppSelector(state => state?.auth?.loggedInUser);

    const [userInfo, setUserInfo] = useState({
        profileImageUrl: user?.profileImageUrl,
        firstName: user?.firstName,
        lastName: user?.lastName,
        about: user?.about
    });
    const [loading, setLoading] = useState(false);

    const handleChange: UploadProps['onChange'] = (info) => {
        if (info?.file?.status === 'done' && info?.file?.response?.statusCode === 200) {
          // Get this url from response in real world.
          const imageUrl = info?.file?.response?.data?.url;
          if(imageUrl) {
            setUserInfo({...userInfo, profileImageUrl: imageUrl});
          } else {
            setUserInfo({...userInfo, profileImageUrl: user?.profileImageUrl});
          }
        }
        if(info?.file?.status === 'error') {
            setUserInfo({...userInfo, profileImageUrl: user?.profileImageUrl});
        }
    };

    const handleProfileUpdate = async () => {
        setLoading(() => true);
        const resp = await updateClientProfile(userInfo);
        if(resp?.data?.statusCode === 200 || resp?.data?.statusCode === 201) {
            setLoading(() => false);
            saveLoggedInUser({...loggedInUser, ...resp?.data?.data});
            dispatch(setCredentials({user: {...user, ...resp?.data?.data}}));
            setIsEditProfile(false);
        } else {
            setLoading(() => false);
        }
    }

    return (
        <div className='EditProfile'>
            <div className='EditProfile__Title'>{CONSTANTS.EDIT_PROFILE}</div>
            <div className='EditProfile__ImageSection'>
                <img src={userInfo?.profileImageUrl} alt={user?.firstName} />
                <Upload
                    className='EditProfile__ImageSection__UploadButton'
                    name='file'
                    multiple={false}
                    maxCount={1}
                    action="https://dev-api.mercurygigs.com/common/upload"
                    onChange={handleChange}
                    headers={{'Authorization': token || ''}}
                    data={{type: 'documents'}}
                    showUploadList={false}
                >
                    {CONSTANTS.UPLOAD_PHOTO}
                </Upload>
            </div>
            <div className='EditProfile__Inputs'>
                <InputBox width='290px' label={CONSTANTS.FIRST_NAME_LABEL} placeholder={CONSTANTS.FIRST_NAME_PLACEHOLDER} value={userInfo?.firstName} onChange={(event) => setUserInfo({...userInfo, firstName: event?.target?.value})} />
                <InputBox width='290px' label={CONSTANTS.LAST_NAME_LABEL} placeholder={CONSTANTS.LAST_NAME_PLACEHOLDER} value={userInfo?.lastName} onChange={(event) => setUserInfo({...userInfo, lastName: event?.target?.value})} />
            </div>
            <div className='EditProfile__AboutInput'>
                <Title className='EditProfile__AboutInput__Title' level={5}>About</Title>
                <TextArea 
                    className='EditProfile__AboutInput__TextArea'
                    value={userInfo?.about}
                    rows={3}
                    variant='filled'
                    placeholder='Tell us about yourself'
                    onChange={(event) => setUserInfo({...userInfo, about: event?.target?.value})}
                />
            </div>
            <div className='EditProfile__Buttons'>
                <CustomButton buttonText={CONSTANTS.BACK} onClick={() => setIsEditProfile(false)} />
                <CustomButton buttonText={CONSTANTS.SAVE} onClick={handleProfileUpdate} loading={loading} />
            </div>
        </div>
    );
}

export default EditProfile;