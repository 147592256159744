const CONSTANTS = {
    TITLES: {
        ACCOUNT_CONFIGURE_TITLE: 'Let’s configure your account',
        FREELANCER_PRFILE_SETUP_TITLE: 'Set up your profile'
    },
    BACK: 'Back',
    NEXT: 'Next',
    ALREADY_HAVE_ACCOUNT: 'Already have an account?',
    GOTO_DASHBOARD: 'Go to dashboard!'
}

export default CONSTANTS;