import React from 'react';
import { Typography } from 'antd';
import CustomButton from '../../../../components/CustomButton';
import visa_inc_logo from "../../../../assets/visa_inc_logo.svg";
import paypal_logo from "../../../../assets/paypal_logo.svg";
import american_express_logo from "../../../../assets/american_express_logo.svg";
import mastercard_symbol from "../../../../assets/mastercard_symbol.svg";
import discover_logo from "../../../../assets/discover_logo.svg";
import './PaymentDetails.scss'
import CONSTANTS from './constants';

interface PaymentDetailsProps {
}

const { Text } = Typography;

const PaymentDetails = () => {
    return (
        <div className='PaymentDetails'>
            <Text strong>{CONSTANTS.PAYMENT_DETAILS_TITLE}</Text>
            <div className='PaymentDetails__PaymentMethods'>
                <img src={visa_inc_logo} alt='visa'/>
                <img src={paypal_logo} alt='paypal'/>
                <img src={american_express_logo} alt='american express'/>
                <img src={mastercard_symbol} alt='mastercard'/>
                <img src={discover_logo} alt='discover'/>
            </div>
            <div className='PaymentDetails__AddCard'>
                <CustomButton buttonText='Add Card' onClick={() => {}} />
            </div>
        </div>
    );
}

export default PaymentDetails;