import React from 'react';
import { Input, Typography } from 'antd';
import './JobOverview.scss';
import CustomButton from '../../components/CustomButton';
import { useAppSelector } from '../../hooks/reduxHooks';

const { Title, Text } = Typography;

interface IJobOverviewProps {
    onApply: () => void
}

const JobOverview = ({onApply}: IJobOverviewProps) => {

    const selectedJob = useAppSelector(state => state?.freelancer?.selectedJob);

    return (
        <div className='JobOverviewContainer'>
            <div className='JobOverviewContainer__FirstRow'>
                <div className='JobOverviewContainer__FirstRow__ClientInfo'>
                    <div className='JobOverviewContainer__FirstRow__ClientInfo__ClientImage'><img src={selectedJob?.client?.profileImageUrl} alt={selectedJob?.client?.firstName} /></div>
                    <div className='JobOverviewContainer__FirstRow__ClientInfo__Details'>
                        <div className='JobOverviewContainer__FirstRow__ClientInfo__Details__ClientName'>{selectedJob?.client?.firstName} {selectedJob?.client?.lastName}</div>
                        <div className='JobOverviewContainer__FirstRow__ClientInfo__Details__JobTitle'>{selectedJob?.jobTitle}</div>
                    </div>
                </div>
                <div className='JobOverviewContainer__FirstRow__Description'>
                    <Title level={5}>Description</Title>
                    <Text>{selectedJob?.jobDesc}</Text>
                </div>
            </div>
            <div className='JobOverviewContainer__SecondRow'>
                <div className='JobOverviewContainer__SecondRow__Header'>
                    <Title level={4}>Details</Title>
                    {!selectedJob?.jobApplication?.status && <CustomButton buttonText='Apply' onClick={onApply} />}
                </div>
                <div className='JobOverviewContainer__SecondRow__Budget'>
                    <Title level={5}>Budget</Title>
                    <Text>{selectedJob?.jobType === 'ongoing' ? `$${selectedJob?.minHourlyRate} - $${selectedJob?.maxHourlyRate}/hr`: `$${selectedJob?.minBudget} - $${selectedJob?.maxBudget}`} - {selectedJob?.jobDurationLabel}</Text>
                </div>
                <div className='JobOverviewContainer__SecondRow__Duration'>
                    <Title level={5}>Project Duration</Title>
                    <Text>{selectedJob?.jobType}</Text>
                </div>
                <div className='JobOverviewContainer__SecondRow__Tags'>
                    <Title level={5}>Tags</Title>
                    <div className='JobOverviewContainer__SecondRow__Tags__List'>
                    {selectedJob?.skills?.map((skill: any, index: number) => (
                        <div key={index} className='JobOverviewContainer__SecondRow__Tags__List__Tile'>{skill}</div>
                    ))}
                    </div>
                </div>
            </div>
            <div className='JobOverviewContainer__ThirdRow'>
                <div className='JobOverviewContainer__ThirdRow__ClientInfo'>
                    <div className='JobOverviewContainer__ThirdRow__ClientInfo__ClientImage'><img src={selectedJob?.client?.profileImageUrl} alt={selectedJob?.client?.firstName} /></div>
                    <div className='JobOverviewContainer__ThirdRow__ClientInfo__Details'>
                        <div className='JobOverviewContainer__ThirdRow__ClientInfo__Details__ClientName'>{selectedJob?.client?.firstName} {selectedJob?.client?.lastName}</div>
                    </div>
                </div>
                <div className='JobOverviewContainer__ThirdRow__AboutClient'>{selectedJob?.about}</div>
            </div>
        </div>
    );
};

export default JobOverview;