import React, { useEffect, useRef, useState } from 'react';
import { Typography, Select, Segmented, Drawer } from 'antd';
import chat from '../../assets/chat.svg';
import notifications from '../../assets/notifications.svg';
import user from '../../assets/user.png';
import './FreelancerJobs.scss';
import CustomButton from '../../components/CustomButton';
import InputBox from '../../components/InputBox';
import { getJobsList, jobApply } from '../../services/jobService';
import JobDetails from '../../components/JobDetails';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import JobApply from '../JobApply';
import { setSelectedJob } from '../../slice/freelancerSlice';
import JobOverview from '../JobOverview';

const FreelancerJobs = () => {

    const dispatch = useAppDispatch();

    const [jobsList, setJobsList] = useState([]);
    const dataFetchedRef = useRef(false);
    const userInfo = useAppSelector(state => state?.auth?.loggedInUser);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [showOverviewScreen, setShowOverviewScreen] = useState(false);

    const fetchAllJobs = async() => {
        const response = await getJobsList();
        if(response?.data?.statusCode === 200) {
            setJobsList(response?.data?.data?.jobs);
        } else {
            setJobsList([]);
        }
    }

    useEffect(() => {
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        fetchAllJobs();
    }, []);

    const onDrawerClose = () => {
        setOpenDrawer(false);
    };

    const handleOnJobClick = (job: any) => {
        dispatch(setSelectedJob({job}));
        setShowOverviewScreen(() => true);
        setOpenDrawer(() => true);
    }

    const handleOnApplyBtnClick = (job: any) => {
        dispatch(setSelectedJob({job}));
        setOpenDrawer(true);
        setShowOverviewScreen(() => false);
    }

    return (
        <div className='FreelancerJobs'>
            <div className='FreelancerJobs__Header'>
                <div className='FreelancerJobs__Header__Title'>Jobs</div>
                <div className='FreelancerJobs__Header__Actions'>
                    <CustomButton buttonText='Upgrade' onClick={() => {}} />
                    <img src={chat} alt='chat' />
                    <img src={notifications} alt='notifications' />
                </div>
            </div>
            <div className='FreelancerJobs__Body'>
                <div className='FreelancerJobs__Body__Filters'>
                    <Segmented
                        className='FreelancerJobs__Body__Filters__JobsSegment'
                        options={['All Jobs', 'Applied']}
                        onChange={(value) => {
                        console.log(value); // string
                        }}
                    />
                    <InputBox className='FreelancerJobs__Body__Filters__SearchInput' placeholder='Search for a Nursing Assistant' onChange={(event) => {}} />
                </div>
                <div className='FreelancerJobs__Body__JobsList'>
                        {jobsList?.map((job: any) => (
                            <JobDetails 
                                userRole={userInfo?.role}
                                jobId={job?.jobId}
                                clientName={`${job?.client?.firstName} ${job?.client?.lastName}`}
                                posterImageUrl={job?.client?.profileImageUrl}
                                jobDurationLabel={job?.jobDurationLabel}
                                hiringType={job?.jobType}
                                jobTitle={job?.jobTitle}
                                minBudget={job?.jobType === 'onetime' ? job?.minBudget : job?.minHourlyRate}
                                maxBudget={job?.jobType === 'onetime' ? job?.maxBudget : job?.maxHourlyRate}
                                jobType={job?.jobType}
                                duration={job?.maxHoursPerWeek}
                                postedDate={job?.createdAt}
                                skills={job?.skills}
                                key={job?.jobId}
                                showApplyButton={!job?.jobApplication?.status}
                                onApplyClick={(event) => {
                                    event.stopPropagation();
                                    handleOnApplyBtnClick(job);
                                }}
                                onClick={() => handleOnJobClick(job)}
                            />
                        ))}
                </div>
            </div>
            <Drawer
                closable={false}
                onClose={onDrawerClose}
                open={openDrawer}
                size={'large'}
            >
                {showOverviewScreen ? <JobOverview onApply={() => setShowOverviewScreen(false)} /> : <JobApply onAfterSubmit={() => fetchAllJobs()} onClose={onDrawerClose} />}
            </Drawer>
        </div>
    );
};

export default FreelancerJobs;