import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from '@reduxjs/toolkit'

export interface LoginState {
    loggedInUser: any
};

const initialState: LoginState = {
    loggedInUser: null
};

const authSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        setCredentials: (state, action: PayloadAction<any>) => {
            const { user } = action.payload;
            state.loggedInUser = user;
        },
        logout: (state) => {
            state.loggedInUser = null;
        },
    },
    extraReducers(builder) {
    }
});

export const { setCredentials, logout } = authSlice.actions;

export default authSlice.reducer;