import { configureStore } from '@reduxjs/toolkit'
import authSlice from './slice/authSlice';
import freelancerSlice from './slice/freelancerSlice';
import clientSlice from './slice/clientSlice';

export const store = configureStore({
    reducer: {
        auth: authSlice,
        freelancer: freelancerSlice,
        client: clientSlice
    },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch