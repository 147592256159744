const CONSTANTS = {
    TITLES: {
        REGISTRATION_CHOICE_TITLE: 'What brings you to Mercury',
        ACCOUNT_SETUP_TITLE: 'Set up your account',
        EMAIL_CONFIRM_TITLE: 'Confirm your email',
        CHOOSE_PASSWORD_TITLE: 'Please choose a password',
        ACCOUNT_CONFIGURE_TITLE: 'Let’s configure your account',
        UPLOAD_PROFILE_TITLE: 'Upload Profile Picture',
        PAYMENT_DETAILS_TITLE: 'Payment details',
        VERIFY_IDENTITY_TITLE: 'Verify identity logo',
        INTERESTED_SERVICES_TITLE: 'Which type of services are you interested in?',
        FREELANCER_PRFILE_SETUP_TITLE: 'Set up your profile'
    },
    BACK: 'Back',
    NEXT: 'Next',
    ALREADY_HAVE_ACCOUNT: 'Already have an account?',
    GOTO_DASHBOARD: 'Go to dashboard!'
}

export default CONSTANTS;