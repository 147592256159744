import React, { useState } from 'react';
import { Divider } from 'antd';
import logoWhite from '../../../../assets/logo-white.svg'
import home from '../../../../assets/home.svg'
import profile from '../../../../assets/profile.svg'
import projects from '../../../../assets/projects.svg'
import requests from '../../../../assets/requests.svg'
import wallet from '../../../../assets/wallet.svg'
import setttings from '../../../../assets/settings.svg'
import discover from '../../../../assets/discover.svg'
import logout from '../../../../assets/logout.svg'
import './Sidebar.scss'
import { Link } from 'react-router-dom';
import { useAppSelector } from '../../../../hooks/reduxHooks';

interface ISideBar {
    handleLogout: () => void
}
const CLIENT_MENU_OPTIONS = [
    {id: 'home', displayLabel: 'Home', redirectUrl: '/dashboard'},
    {id: 'profile', displayLabel: 'Profile', redirectUrl: '/profile'},
    {id: 'projects', displayLabel: 'My Projects', redirectUrl: '/client-projects'},
    {id: 'requests', displayLabel: 'Requests', redirectUrl: '/requests'},
    {id: 'wallet', displayLabel: 'Wallet', redirectUrl: '/wallet'},
    {id: 'settings', displayLabel: 'Settings', redirectUrl: '/settings'},
    {id: 'discover', displayLabel: 'Discover', redirectUrl: '/discover'}
];

const FREELANCER_MENU_OPTIONS = [
    {id: 'dashboard', displayLabel: 'Dashboard', redirectUrl: '/dashboard'},
    {id: 'analytics', displayLabel: 'Analytics', redirectUrl: '/analytics'},
    {id: 'jobs', displayLabel: 'Jobs', redirectUrl: '/jobs'},
    {id: 'portfolio', displayLabel: 'Portfolio', redirectUrl: '/portfolio'},
    {id: 'discover', displayLabel: 'Discover', redirectUrl: '/discover'},
    {id: 'profile', displayLabel: 'Profile', redirectUrl: '/profile'},
    {id: 'wallet', displayLabel: 'Wallet', redirectUrl: '/wallet'},
    {id: 'settings', displayLabel: 'Settings', redirectUrl: '/settings'},
    
];

// const MENU_OPTIONS_ICONS = {
//     'home': home,
//     'profile': profile,
//     'projects': projects,
//     'requests': requests,
//     'wallet': wallet,
//     'settings': setttings,
//     'discover': discover
// };

const getMenuIcon = (menuId: string) => {
    switch(menuId) {
        case 'home':
            return home;
        case 'profile':
            return profile;
        case 'projects':
            return projects;
        case 'requests':
            return requests;
        case 'wallet':
            return wallet;
        case 'settings':
            return setttings;
        case 'discover':
            return discover;
        default:
            return home;
    }
}

const Sidebar = ({handleLogout}: ISideBar) => {
    const userInfo = useAppSelector(state => state?.auth?.loggedInUser);
    const options = userInfo?.role === 'freelancer' ? FREELANCER_MENU_OPTIONS : CLIENT_MENU_OPTIONS;
    const currentPathName = window.location.pathname
    const [selectedOption, setSelectedOption] = useState(currentPathName);

    return (
        <div className='SidebarContainer'>
            <div className='SidebarContainer__UpperSection'>
                <div className='SidebarContainer__UpperSection__Header'>
                    <img src={logoWhite} alt='Mercury logo' />
                    <div className='SidebarContainer__UpperSection__Header__UserInfo'>
                        <img className='SidebarContainer__UpperSection__Header__UserInfo__Image' src={userInfo?.profileImageUrl} alt='logged-in-user' />
                        <span className='SidebarContainer__UpperSection__Header__UserInfo__Name'>{`${userInfo?.firstName} ${userInfo?.lastName}`} </span>
                    </div>
                </div>
                <Divider className='SidebarContainer__UpperSection__Divider' />
                <div className='SidebarContainer__UpperSection__MenuOptions'>
                    {options?.map(({id, displayLabel, redirectUrl}) => (
                        <Link to={redirectUrl} className={`SidebarContainer__UpperSection__MenuOptions__Option ${selectedOption === redirectUrl ? 'SidebarContainer__UpperSection__MenuOptions__Option__Selected' : ''}`} key={id} onClick={() => setSelectedOption(redirectUrl)}>
                            <img src={getMenuIcon(id)} alt={displayLabel} />
                            {displayLabel}
                        </Link>
                    ))}
                </div>
            </div>
            <div className='SidebarContainer__LowerSection' onClick={handleLogout}>
                <img src={logout} alt='Log out' width='28px' height={'28px'} />Logout
            </div>
        </div>
    );
}

export default Sidebar;