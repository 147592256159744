import React from 'react';
import verified from '../../assets/verifed.svg'
import './JobDetails.scss';
import CustomButton from '../CustomButton';

interface JobDetailsProps {
    jobId: string
    clientName: string
    posterImageUrl: string
    hiringType?: string
    jobTitle: string
    minBudget: string
    maxBudget: string
    jobType: string
    duration?: string
    postedDate: string
    isVerified?: boolean
    jobDurationLabel: string
    skills: string[]
    userRole: string
    showApplyButton?: boolean
    showClientImage?: boolean
    showClientName?: boolean
    showViewApplicationsButton?: boolean
    applicationStatus?: string
    applicationsCount?: string
    onViewApplicationsClick?: (jobId: string) => void
    onApplyClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void
    onClick?: () => void
}

const DURATION = {
    'ongoing': 'Ongoing',
    'onetime': 'One time'
}


const JobDetails = ({userRole, jobId, clientName, posterImageUrl, jobDurationLabel, hiringType = '', jobTitle, minBudget, maxBudget, jobType, duration = '', postedDate, isVerified  = true, skills, showApplyButton = false, showClientImage = true, showClientName = true, applicationStatus = '', applicationsCount = '', onApplyClick = () => {}, onClick = () => {}, showViewApplicationsButton = false, onViewApplicationsClick = () => {}}: JobDetailsProps) => {
    return (
        <div className='JobDetails' onClick={onClick}>
            <div className='JobDetails__FirstRow'>
                <div className='JobDetails__FirstRow__PosterInfo'>
                    {showClientImage && <div className='JobDetails__FirstRow__PosterInfo__UserImage'><img src={posterImageUrl} alt={clientName} /></div>}
                    <div className='JobDetails__FirstRow__PosterInfo__Details'>
                        {showClientName && (
                            <div className='JobDetails__FirstRow__PosterInfo__Details__HiringType'>
                                {/* {clientName} - Hiring as an {hiringType} */}
                                {clientName}
                                {isVerified && (<span className='JobDetails__FirstRow__PosterInfo__Details__HiringType__Verified'><img src={verified} alt='verified' width={'20px'} height={'20px'}/></span>)}
                            </div>
                        )}
                        <div className='JobDetails__FirstRow__PosterInfo__Details__JobTitle'>{jobTitle}</div>
                    </div>
                </div>
                {(userRole === 'freelancer' && showApplyButton) 
                    ? <CustomButton onClick={onApplyClick} buttonText='Apply' />
                    : (userRole === 'client' && showViewApplicationsButton)
                        ? <CustomButton onClick={() => onViewApplicationsClick(jobId)} buttonText='View Applications' /> 
                        : <div className='JobDetails__FirstRow__AdditionInfo'>
                            <div className='JobDetails__FirstRow__AdditionInfo__PostedDate'>{new Date(postedDate).toLocaleDateString()}</div>
                            {applicationStatus && (<div className='JobDetails__FirstRow__AdditionInfo__Status'>{applicationStatus}</div>)}
                        </div>
                }
            </div>
            <div className='JobDetails__SecondRow'>
                {skills?.map((skill, index) => (
                    <div key={index} className='JobDetails__SecondRow__Tag'>{skill}</div>
                ))}
            </div>
            <div className='JobDetails__ThirdRow'>
                <div className='JobDetails__ThirdRow__Tile'>${minBudget} - ${maxBudget}{jobType === 'ongoing' && '/hr'}</div>
                <div className='JobDetails__ThirdRow__Tile'>{jobDurationLabel}</div>
                <div className='JobDetails__ThirdRow__Tile'>Duration: {DURATION[jobType as keyof typeof DURATION]}</div>
                {applicationsCount && (<div className='JobDetails__ThirdRow__Tile'>Application Received: {applicationsCount}</div>)}
            </div>
        </div>
    );
}

export default JobDetails;