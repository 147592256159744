import React from 'react';
import { Typography, Select } from 'antd';
import add from '../../assets/add.svg';
import star from '../../assets/star.svg';
import './FreelancerTile.scss';
import CustomButton from '../CustomButton';

type JobTitle = {
    label: string
    id: number | string
}

interface FreelancerTileProps {
    freelancerId: string
    userName: string
    imageUrl: string
    professionalTitle: string
    rating: string
    jobTitles: string[]
    minRate?: string
    maxRate?: string
    showBudget?: boolean
    button1Text?: string
    onButton1Click?: (freelancerId: string) => void
}

const { Title } = Typography;


const FreelancerTile = ({freelancerId, userName, imageUrl, professionalTitle, rating, jobTitles, minRate = '', maxRate = '', showBudget = true, button1Text = 'View work', onButton1Click = () => {}}: FreelancerTileProps) => {
    return (
        <div className='FreelancerTile' id={freelancerId}>
            <div className='FreelancerTile__FirstRow'>
                <div className='FreelancerTile__FirstRow__UserInfo'>
                    <img className='FreelancerTile__FirstRow__UserInfo__Image' src={imageUrl} alt={userName} />
                    <div className='FreelancerTile__FirstRow__UserInfo__Details'>
                        <div className='FreelancerTile__FirstRow__UserInfo__Details__Name'>
                            <Title level={4} style={{marginBottom: "0px"}}>{userName}</Title>
                            <div className='FreelancerTile__FirstRow__UserInfo__Details__Name__Rating'>
                                <img src={star} alt='rating' />
                                <>{rating}</>
                            </div>
                        </div>
                        <div className='FreelancerTile__FirstRow__UserInfo__Details__ProfessionalTitle'>{professionalTitle}</div>
                    </div>
                </div>
                <div className='FreelancerTile__FirstRow__Actions'>
                    <CustomButton buttonText={button1Text} onClick={() => onButton1Click(freelancerId)} />
                    <CustomButton buttonText='Get in touch' onClick={() => {}} />
                </div>
            </div>
            <div className='FreelancerTile__SecondRow'>
                {jobTitles?.map((title, index) => <div className='FreelancerTile__SecondRow__JobTitle' key={index}>{title}</div>)}
            </div>
            {showBudget && (
                <div className='FreelancerTile__ThirdRow'>
                    ${minRate} - ${maxRate}/hour
                </div>
            )}
        </div>
    );
}

export default FreelancerTile;