import React, { useState, useEffect, useContext } from 'react';

interface AuthContextType {
    token: string | null
    user: any
    saveToken: (userToken: string | null) => void
    saveLoggedInUser: (user: any) => void
}

const AuthContext = React.createContext<AuthContextType>({
    token: null,
    user: null,
    saveToken: (userToken: string | null) => {},
    saveLoggedInUser: (user: any) => {}
});

export function useAuth() {
    return useContext(AuthContext);
}

export function AuthProvider(props: any) {

    const getLoggedInUserInfo = () => {
        const user = JSON.parse(sessionStorage?.getItem('loggedInUser') || 'null');
        return user;
    }

    const getToken = () => {
        const tokenString = sessionStorage?.getItem('token') || 'null';
        const userToken = JSON.parse(tokenString);
        return userToken;
    };

    const [token, setToken] = useState(getToken());
    const [user, setUser] = useState(getLoggedInUserInfo());

    // useEffect(() => {
    // }, []);

    const saveToken = (userToken: string | null) => {
        sessionStorage.setItem('token', JSON.stringify(userToken));
        setToken(userToken);
    };

    const saveLoggedInUser = (user: any) => {
        sessionStorage.setItem('loggedInUser', JSON.stringify(user));
        setUser(user);
    }

    const value = {
        saveToken,
        saveLoggedInUser,
        user,
        token
    };

    return (
        <AuthContext.Provider value={value}>
            {props.children}
        </AuthContext.Provider>
    )
}