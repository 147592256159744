import React from 'react';
import './RegistrationChoice.scss'
import CONSTANTS from './constants';

interface RegistrationChoiceProps {
    selectedOption?: string
    handleOptionChange?: (value: any) => void
}

const RegistrationChoice = ({selectedOption, handleOptionChange}: RegistrationChoiceProps) => {
    return (
        <div className="options">
          <label className={`option ${selectedOption === 'freelancer' ? 'selected' : ''}`}>
            <input
              type="radio"
              value="freelancer"
              checked={selectedOption === 'freelancer'}
              onChange={handleOptionChange}
            />
            <span className="custom-radio"></span>
              {CONSTANTS.FREELANCER}
            <span className="description">{CONSTANTS.FREELANCER_DESCRIPTION}</span>
          </label>
          <label className={`option ${selectedOption === 'client' ? 'selected' : ''}`}>
            <input
              type="radio"
              value="client"
              checked={selectedOption === 'client'}
              onChange={handleOptionChange}
            />
            <span className="custom-radio"></span>
              {CONSTANTS.CLIENT}
            <span className="description">{CONSTANTS.CLIENT_DESCRIPTION}</span>
          </label>
        </div>
    );
}

export default RegistrationChoice;