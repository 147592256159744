import React from 'react';
import './Jobs.scss';
import JobDetails from '../../../../components/JobDetails';
import JobPost from '../../../../components/JobPost';
import CONSTANTS from './constants';
import { useAppSelector } from '../../../../hooks/reduxHooks';

interface Job {
    jobId: string
    client: {
        firstName: string
        lastName: string
        profileImageUrl: string
        isVerified: boolean
    }
    jobTitle: string
    minBudget: string
    maxBudget: string
    minHourlyRate: string
    maxHourlyRate: string
    jobType: string
    jobDurationLabel: string
    createdAt: string
    skills: string[]
}

interface JobsProps {
    pastJobs?: Job[] | []
    setAddNewJob: React.Dispatch<React.SetStateAction<boolean>>
}


const Jobs = ({pastJobs = [], setAddNewJob}: JobsProps) => {
    const userInfo = useAppSelector(state => state?.auth?.loggedInUser);
    return (
        <div className='JobsCard'>
            <div className='JobsCard__Title'>{CONSTANTS.JOBS}</div>
            <div className='JobsCard__PostNewJob'>
                <JobPost title={CONSTANTS.POST_JOB_TITLE} subTitle={CONSTANTS.POST_JOB_SUBTITLE} handleOnClickAction={() => setAddNewJob(true)}/>
            </div>
            <div className='JobsCard__PastJobs'>
                {pastJobs?.map((job) => (
                    <JobDetails
                        key={job?.jobId}
                        jobId={job?.jobId}
                        userRole={userInfo?.role}
                        clientName={`${job?.client?.firstName} ${job?.client?.lastName}`}
                        posterImageUrl={job?.client?.profileImageUrl}
                        jobTitle={job?.jobTitle}
                        minBudget={job?.jobType === 'onetime' ? job?.minBudget : job?.minHourlyRate}
                        maxBudget={job?.jobType === 'onetime' ? job?.maxBudget : job?.maxHourlyRate}
                        jobType={job?.jobType}
                        postedDate={job?.createdAt}
                        jobDurationLabel={job?.jobDurationLabel}
                        skills={job?.skills}
                    />
                ))}
            </div>
        </div>
    );
}

export default Jobs;