import React, { useState } from 'react';
import InputBox from '../../../../components/InputBox';
import './AccountSetup.scss'
import CONSTANTS from './constants';

interface AccountId {
    [key: string]: string
}

interface AccountSetupProps {
    accountId: AccountId
    isInvalidAccountId: boolean
    errorMessage?: string
    handleOnChange: (key: string, event: string) => void
}

const AccountSetup = ({accountId, isInvalidAccountId, errorMessage = '', handleOnChange}: AccountSetupProps) => {

    return (
        <InputBox
            className='AccountSetup-Email'
            status={isInvalidAccountId ? 'error' : ''}
            label={CONSTANTS.EMAIL_PHONE_LABEL} 
            value={accountId?.phone || accountId?.email}
            variant='filled'
            placeholder={CONSTANTS.EMAIL_PHONE_PLACEHOLDER} 
            onChange={(event) => handleOnChange('email', event.target?.value)}
            errorMessage={errorMessage}
        />
    );
}

export default AccountSetup;