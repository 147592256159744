const CONSTANTS = {
    SIGN_IN: 'Sign in',
    EMAIL_LABEL: 'Email',
    EMAIL_PLACEHOLDER: 'Type your email',
    PASSWORD_LABEL: 'Password',
    PASSWORD_PLACEHOLDER: 'Type your password',
    FORGOT_PASSWORD: 'Forgot your password?',
    NO_ACCOUNT: 'Don\'t have an account?',
    STARTED_WITH_MERCURY: 'Get started with Mercury!'
}

export default CONSTANTS;