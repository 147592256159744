import React from 'react';
import { Modal } from 'antd';
import './CustomModal.scss';

interface CustomModalProps {
    title?: React.ReactNode
    closable?: boolean
    footer?: React.ReactNode | React.ReactNode[]
    isOpen?: boolean
    children?: React.ReactNode
    centered?: boolean
    width?: string | number
    onCancel?: () => void
}


const CustomModal = ({title = null, closable = true, footer = null, isOpen = false, children = null, centered = false, width = 520, onCancel = () => {}}: CustomModalProps) => {
    return (
        <Modal className='CustomModal' closable={closable} footer={footer} title={title} open={isOpen} centered={centered} width={width} onCancel={onCancel}>
            {children}
        </Modal>
    );
}

export default CustomModal;