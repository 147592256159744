const CONSTANTS = {
    EDIT_PROFILE: 'Edit Profile',
    UPLOAD_PHOTO: 'Upload photo',
    FIRST_NAME_PLACEHOLDER: 'Type your first name',
    FIRST_NAME_LABEL: 'First Name',
    LAST_NAME_PLACEHOLDER: 'Type your last name',
    LAST_NAME_LABEL: 'Last Name',
    BACK: 'Back',
    SAVE: 'Save',
}

export default CONSTANTS;