import React from 'react';
import add from '../../assets/add.svg';
import './JobPost.scss';

interface JobPostProps {
    title: string
    subTitle: string
    handleOnClickAction: () => void
}


const JobPost = ({title, subTitle, handleOnClickAction}: JobPostProps) => {
    return (
        <div className='JobPost' onClick={handleOnClickAction}>
            <div className='JobPost__Icon'>
                <img src={add} alt='Add Job' height={'62px'} width={'62px'} />
            </div>
            <div className='JobPost__Text'>
                <div className='JobPost__Text__Title'>{title}</div>
                <div className='JobPost__Text__SubTitle'>{subTitle}</div>
            </div>
        </div>
    );
}

export default JobPost;