const CONSTANTS = {
    PROFESSIONAL_TITLE: 'Professional Title',
    PROFESSIONAL_TITLE_PLACEHOLDER: 'Enter your professional title',
    INDUSTRY: 'Industry',
    INDUSTRY_PLACEHOLDER: 'Select your industry',
    CATEGORY: 'Select Category',
    Category_PLACEHOLDER: 'Select your category',
    SERVICE_QUESTION: 'What types of services do you offer?',
    WORKING_HOURS: 'Working Hours',
    OPENING_HOURS: 'Opening hours',
    CLOSING_HOURS: 'Closing hours',
    PORTFOLIO_LINKS: 'Link your portfolio',
    ERROR_MESSAGES: {
        PROFESSIONAL_TITLE: 'Please enter professional title',
        INDUSTRY: 'Please select valid industry',
        CATEGORY: 'Please select valid category',
        SERVICE: 'Please select valid service',
        HOURS: 'Please select valid hours',
        PORTFOLIO: 'Please enter portfolio url'
    }
}

export default CONSTANTS;