import React, { useState } from 'react';
import type { RadioChangeEvent } from 'antd';
import { Typography, Radio, Checkbox } from 'antd';
import dollar from '../../assets/dollar.svg';
import './Question.scss'
import InputBox from '../InputBox';

type option = {
    label: string
    value: string
    [key: string]: string
}

interface QuestionProps {
    id?: string
    className?: string
    label?: string
    options?: option[]
    variant?: 'singleChoice' | 'multipleChoice' | 'range'
    value?: any
    onChange: (key: string, value: string | string[]) => void
}

const { Title } = Typography;


const Question = ({
    id = '',
    className = '',
    label = '',
    variant = 'singleChoice',
    value = '',
    options = [],
    onChange
}: QuestionProps) => {

    const [range, setRange] = useState({
        from: '',
        to: ''
    });

    const onOptionsChange = (e: RadioChangeEvent) => {
        onChange('', e.target.value);
    };

    const onMultipleOptionsChange = (checkedValues: string[]) => {
        onChange('', checkedValues);
    };

    const onRangeInputsChange = (key: string, value: string) => {
        setRange({...range, [key]: value});
        onChange(key, value);
    }


    return (
        <div className={`${className} Question__Container`} id={id}>
            {label && <Title className='Question__Container__Label' level={5}>{label}</Title>}
            {(variant === 'singleChoice' && !!options?.length) && (
                <Radio.Group onChange={onOptionsChange} value={value} className='Question__Container__OptionsList'>
                    {options?.map((option, index) => (
                        <Radio value={option?.value} key={index} className='Question__Container__OptionsList__Option'>{option?.label}</Radio>
                    ))}
                </Radio.Group>
            )}
            {(variant === 'multipleChoice' && !!options?.length) && (
                <Checkbox.Group options={options} value={value} defaultValue={[]} onChange={onMultipleOptionsChange} className='Question__Container__OptionsList' />
            )}
            {variant === 'range' && (
                <div className='Question__Container__RangeInputs'>
                    <InputBox className='Question__Container__RangeInputs__From' label='From' value={value?.from} variant='filled' prefix={<img src={dollar} alt={'dollar'}/>} onChange={(event) => onRangeInputsChange('from', event?.target?.value)} />
                    <InputBox className='Question__Container__RangeInputs__To' label='To' value={value?.to} variant='filled' prefix={<img src={dollar} alt={'dollar'}/>} onChange={(event) => onRangeInputsChange('to', event?.target?.value)} />
                </div>
            )}
        </div>
    );
}

export default Question;