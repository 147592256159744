import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from '@reduxjs/toolkit'

export interface FreelancerState {
    selectedJob: any
};

const initialState: FreelancerState = {
    selectedJob: null
};

const freelancerSlice = createSlice({
    name: 'freelancer',
    initialState,
    reducers: {
        setSelectedJob: (state, action: PayloadAction<any>) => {
            const { job } = action.payload;
            state.selectedJob = job;
        },
    },
    extraReducers(builder) {
    }
});

export const { setSelectedJob } = freelancerSlice.actions;

export default freelancerSlice.reducer;