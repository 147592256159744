const CONSTANTS = {
    FULL_NAME_PLACEHOLDER: 'Type your full name',
    FULL_NAME_LABEL: 'Full Name',
    USERNAME_PLACEHOLDER: 'Type your username',
    USERNAME_LABEL: 'User Name',
    PHONE_NUMBER_PLACEHOLDER: 'Type your phone number',
    PHONE_NUMBER_LABEL: 'Phone Number',
    DOB_LABEL: 'Date of Birth',
    LOCATION_PLACEHOLDER: 'Type your location',
    LOCATION_LABEL: 'Location',
    ERROR_MESSAGES: {
        FULL_NAME: 'Please enter full name',
        USER_NAME: 'Please enter user name',
        PHONE_NUMBER: 'Please enter phone number',
        DATE_OF_BIRTH: 'Please enter DOB',
        LOCATION: 'Please enter location',
        PROFESSIONAL_TITLE: 'Please enter professional title',
        WORKING_HOURS: 'Please select valid hours',
        PORTFOLIO_LINK: 'Please link your portfolio'
    },
}

export default CONSTANTS;