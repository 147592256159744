import React from 'react';
import { Divider } from "antd";
import map from '../../assets/map.svg';
import phone from '../../assets/phone.svg';
import calendar from '../../assets/calendar.svg';
import user from '../../assets/user.png';
import edit from '../../assets/edit.svg';
import behance from '../../assets/behance.svg';
import dribble from '../../assets/dribble.svg';
import CustomButton from '../CustomButton';
import './ProfileCard.scss';

interface ProfileCardProps {
    imageUrl?: string
    userName?: string
    phoneNumber?: string
    dob?: string
    role?: string
    about?: string
    location?: string
    responseTime?: string
    setIsEditProfile: React.Dispatch<React.SetStateAction<boolean>>
}

const skills = [
    {label: 'Image retouching', id: 1},
    {label: 'Fashion photography', id: 2},
    {label: 'Headshot photography', id: 3},
    {label: 'Framer Developer', id: 4},
]


const ProfileCard = ({
    imageUrl = '', 
    userName = '',
    phoneNumber = '',
    dob = '',
    location = '',
    about = '',
    role = 'client',
    responseTime = '',
    setIsEditProfile}: ProfileCardProps) => {
    return (
        <div className='ProfileCard'>
            <div className='ProfileCard__UserImage'>
                <img className='ProfileCard__UserImage__Image' src={imageUrl} alt={userName} />
                <CustomButton className='ProfileCard__UserImage__VerifiedButton' buttonText='Verified' onClick={() => {}} />
                {/* <Link to={'/edit-profile'} className='ProfileCard__UserImage__EditProfile'><img src={edit} alt='profile edit' /></Link> */}
                <div className='ProfileCard__UserImage__EditProfile' onClick={() => setIsEditProfile(true)}><img src={edit} alt='profile edit' /></div>
            </div>
            <div className='ProfileCard__UserName'>
                {userName}
            </div>
            {role === 'client' && (
                <div className='ProfileCard__About'>
                    <div className='ProfileCard__About__Title'>About</div>
                    <div className='ProfileCard__About__Info'>
                        <div>
                            {about?.length ? about : '-'}
                        </div>
                        <Divider className='ProfileCard__About__Info__Divider' />
                        <div>
                            <img src={map} alt='map pointer'/>{location}
                        </div>
                        <Divider className='ProfileCard__About__Info__Divider' />
                        <div>
                            <img src={phone} alt='phone'/>{phoneNumber}
                        </div>
                        <Divider className='ProfileCard__About__Info__Divider' />
                        <div>
                            <img src={calendar} alt='calendar'/>{dob}
                        </div>
                    </div>
                </div>
            )}
            {role === 'freelancer' && (
                <>
                    <div className='ProfileCard__GetInTouch'>
                        <CustomButton buttonText='Get in touch' onClick={() => {}} />
                        <div className='ProfileCard__GetInTouch__RespondTime'>responds within {responseTime} hours</div>
                    </div>
                    <div className='ProfileCard__FreelancerAbout'>
                        <div className='ProfileCard__FreelancerAbout__Description'>
                            <div className='ProfileCard__FreelancerAbout__Description__Title'>
                                Description
                            </div>
                            <div className='ProfileCard__FreelancerAbout__Description__Body'>
                                I'm a professional photographer. I’m highly adaptable and experienced, specializing in weddings, engagements and portraiture photography.
                            </div>
                            <div className='ProfileCard__FreelancerAbout__Description__Location'>
                                <img src={map} alt='map pointer'/>Santa Monica
                            </div>
                        </div>
                        <Divider className='ProfileCard__FreelancerAbout__Divider' />
                        <div className='ProfileCard__FreelancerAbout__SkillSet'>
                            <div className='ProfileCard__FreelancerAbout__SkillSet__Title'>
                                Skill Set
                            </div>
                            <div className='ProfileCard__FreelancerAbout__SkillSet__Body'>
                                {skills?.map(skill => (<div className='ProfileCard__FreelancerAbout__SkillSet__Body__SkillTile' key={skill?.id}>{skill?.label}</div>))}
                            </div>
                        </div>
                        <Divider className='ProfileCard__FreelancerAbout__Divider' />
                        <div className='ProfileCard__FreelancerAbout__Links'>
                            <div className='ProfileCard__FreelancerAbout__Links__Title'>
                                Links
                            </div>
                            <div className='ProfileCard__FreelancerAbout__Links__Body'>
                               <div><img src={behance} alt='behance link' /></div>
                               <div><img src={dribble} alt='behance link' /></div>
                            </div>
                        </div>
                        <Divider className='ProfileCard__FreelancerAbout__Divider' />
                        <div className='ProfileCard__FreelancerAbout__Milestones'>
                            <div className='ProfileCard__FreelancerAbout__Milestones__Title'>
                                Milestones
                            </div>
                            <div className='ProfileCard__FreelancerAbout__Milestones__Body'>
                               <div className='ProfileCard__FreelancerAbout__Milestones__Body__Tile'>50 Projects completed</div>
                               <div className='ProfileCard__FreelancerAbout__Milestones__Body__Tile'>30 5-star reviews</div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}

export default ProfileCard;