import React from 'react';
import { DatePicker, Input, Typography } from 'antd';
import InputBox from '../../../../components/InputBox';
import './AccountDetails.scss'
import CONSTANTS from './constants';

type ValidationState = {
    fullName: boolean
    userName: boolean
    phoneNumber: boolean
    dateOfBirth: boolean
    location: boolean
}

interface AccountDetailsProps {
    fullName: string
    userName: string
    phoneNumber: string
    dateOfBirth: string
    about: string
    location: string
    validationErrors: ValidationState
    handleOnChange: (key: string, value: string) => void
}

const { Title, Text } = Typography;
const { TextArea } = Input;

const AccountDetails = ({fullName = '', userName = '', phoneNumber = '', dateOfBirth = '', about = '', location = '', validationErrors, handleOnChange}: AccountDetailsProps) => {

    const handleOnDobChange = (date: string, dateString: string | string[]) => {
        const selectedDate = Array.isArray(dateString) ? dateString[0] : dateString
        handleOnChange('dateOfBirth', selectedDate);
    }

    return (
        <div className='AccountDetails'>
            <div className='AccountDetails__FirstRow'>
                <InputBox width='235px' className='AccountDetails__FirstRow__FirstName' label={CONSTANTS.FULL_NAME_LABEL} value={fullName} variant='filled' placeholder={CONSTANTS.FULL_NAME_PLACEHOLDER} status={validationErrors?.fullName ? 'error' : ''} errorMessage={CONSTANTS.ERROR_MESSAGES.FULL_NAME} onChange={(event) => handleOnChange('fullName', event?.target?.value)} />
                <InputBox width='235px' className='AccountDetails__FirstRow__UserName' label={CONSTANTS.USERNAME_LABEL} value={userName} variant='filled' placeholder={CONSTANTS.USERNAME_PLACEHOLDER} status={validationErrors?.userName ? 'error' : ''} errorMessage={CONSTANTS.ERROR_MESSAGES.USER_NAME} onChange={(event) => handleOnChange('userName', event?.target?.value)} />
            </div>
            <div className='AccountDetails__SecondRow'>
                <InputBox className='AccountDetails__SecondRow__PhoneNumber' label={CONSTANTS.PHONE_NUMBER_LABEL} value={phoneNumber} variant='filled' placeholder={CONSTANTS.PHONE_NUMBER_PLACEHOLDER} status={validationErrors?.phoneNumber ? 'error' : ''} errorMessage={CONSTANTS.ERROR_MESSAGES.PHONE_NUMBER} onChange={(event) => handleOnChange('phoneNumber', event?.target?.value)} />
                <div className='AccountDetails__SecondRow__DOB'>
                    <Title className='AccountDetails__SecondRow__DOB__Title' level={5}>{CONSTANTS.DOB_LABEL}</Title>
                    <DatePicker className='AccountDetails__SecondRow__DOB__DatePicker' status={validationErrors?.dateOfBirth ? 'error' : ''} onChange={handleOnDobChange} />
                    {validationErrors?.dateOfBirth && <Text type='danger'>{CONSTANTS.ERROR_MESSAGES.DATE_OF_BIRTH}</Text>}
                </div>
            </div>
            <div className='AccountDetails__ThirdRow'>
                <Title className='AccountDetails__ThirdRow__Title' level={5}>About</Title>
                <TextArea 
                    className='AccountDetails__ThirdRow__TextArea'
                    value={about} 
                    rows={3}
                    variant='filled'
                    placeholder='Tell us about yourself'
                    onChange={(event) => handleOnChange('about', event?.target?.value)}
                />
            </div>
            <div className='AccountDetails__FourthRow'>
                <InputBox className='AccountDetails__FourthRow__Location' label={CONSTANTS.LOCATION_LABEL} value={location} variant='filled' placeholder={CONSTANTS.LOCATION_PLACEHOLDER} status={validationErrors?.location ? 'error' : ''} errorMessage={CONSTANTS.ERROR_MESSAGES.LOCATION} onChange={(event) => handleOnChange('location', event?.target?.value)} />
            </div>
        </div>
    );
}

export default AccountDetails;