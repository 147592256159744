import axiosInstance from "./axiosConfig";

export const getJobsList = async () => {
    try {
        const response = await axiosInstance.get('job');
        return response;
    } catch(err) {
        console.log('>>>> getJobsList >>> ERROR-> ', err);
    }
}

interface NewJobDetails {
    jobTitle: string
    jobDesc: string
    skills: string[] | []
    tools: string[] | []
    jobType: string
    minBudget?: string
    maxBudget?: string
    maxHours?: string
    paymentRateType?: string
    jobDuration?: string
    jobDurationType?: string
    minHourlyRate?: string
    maxHourlyRate?: string
    maxHoursPerWeek?: string
}

export const createJob = async (payload: NewJobDetails) => {
    try {
        const response = await axiosInstance.post('job', payload);
        return response;
    } catch(err) {
        console.log('>>>> createJob >>> ERROR-> ', err);
    }
}

export const jobApply = async (payload: {
    jobId: string
    description: string
    projects: string[] | []
}) => {
    try {
        const response = await axiosInstance.post('job/apply', payload);
        return response;
    } catch(err: any) {
        console.log('>>>> jobApply >>> ERROR-> ', err);
        return err?.response;
    }
}

export const getClientPostedJobs = async () => {
    try {
        const response = await axiosInstance.get('job/history');
        return response;
    } catch(err) {
        console.log('>>>> getClientPostedJobs >>> ERROR-> ', err);
    }
}

export const getApplicationsByJobId = async (jobId: string) => {
    try {
        const response = await axiosInstance.get(`job/applications?jobId=${jobId}`);
        return response;
    } catch(err) {
        console.log('>>>> getApplicationsByJobId >>> ERROR-> ', err);
    }
}

export const acceptApplication = async (requestBody: {
    applicationId: string
}) => {
    try {
        const response = await axiosInstance.post('job/applications', requestBody);
        return response;
    } catch(err) {
        console.log('>>>> acceptApplication >>> ERROR-> ', err);
    }
}