import React, { useEffect, useRef, useState } from 'react';
import ProfileCard from '../../../components/ProfileCard';
import CustomButton from '../../../components/CustomButton';
import user from '../../../assets/user.png';
import chat from '../../../assets/chat.svg';
import notifications from '../../../assets/notifications.svg';
import './Profile.scss'
import EditProfile from './EditProfile';
import Jobs from './Jobs';
import JobPostModalBody from './JobPostModalBody';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { getClientPostedJobs } from '../../../services/jobService';

// const pastJobs = [
//     {
//         jobId: '1',
//         clientName: 'John Smith',
//         posterImageUrl: user,
//         hiringType: 'individual',
//         jobTitle: 'Telemarketing Agent',
//         minBudget: '500',
//         maxBudget: '1000',
//         jobType: 'oneTime',
//         duration: '1 week',
//         postedDate: '10d ago',
//         skills: ['Web Designer', 'Web Developer', 'Webflow'],
//         jobDurationLabel: 'Sbc'
//     },
//     {
//         jobId: '2',
//         clientName: 'John Smith',
//         posterImageUrl: user,
//         hiringType: 'individual',
//         jobTitle: 'Telemarketing Agent',
//         minBudget: '500',
//         maxBudget: '1000',
//         jobType: 'oneTime',
//         duration: '1 week',
//         postedDate: '10d ago',
//         skills: ['Web Designer', 'Web Developer', 'Webflow'],
//         jobDurationLabel: 'Sbc'
//     }
// ]


const Profile = () => {
    const dataFetchedRef = useRef(false);
    const [isEditProfile, setIsEditProfile] = useState<boolean>(false);
    const [addNewJob, setAddNewJob] = useState<boolean>(false);
    const [postedJobs, setPostedJobs] = useState([]);

    const userInfo = useAppSelector(state => state?.auth?.loggedInUser);

    const fetchClientJobs = async() => {
        if(userInfo?.role === 'client') {
            const response = await getClientPostedJobs();
            if(response?.data?.statusCode === 200) {
                setPostedJobs(response?.data?.data?.jobs);
            } else {
                setPostedJobs([]);
            }
        } else {
            setPostedJobs(() => []);
        }
    }

    useEffect(() => {
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        fetchClientJobs();
    }, []);

    return (
        <>
            <div className='Profile'>
                <div className='Profile__Header'>
                    <div className='Profile__Header__Title'>Profile</div>
                    <div className='Profile__Header__Actions'>
                        <CustomButton buttonText='Upgrade' onClick={() => {}} />
                        <img src={chat} alt='chat' />
                        <img src={notifications} alt='notifications' />
                    </div>
                </div>
                <div className='Profile__Body'>
                    {isEditProfile ?
                        <div className='Profile__Body__EditScreen'><EditProfile imageUrl={userInfo?.profileImageUrl} firstName={userInfo?.firstName} lastName={userInfo?.lastName} setIsEditProfile={setIsEditProfile} /></div>
                        : (
                            <>
                                <div className='Profile__Body__ProfileCard'><ProfileCard role={userInfo?.role} userName={`${userInfo?.firstName} ${userInfo?.lastName}`} imageUrl={userInfo?.profileImageUrl} phoneNumber={userInfo?.phone} dob={userInfo?.dateOfBirth} location={userInfo?.location} about={userInfo?.about} setIsEditProfile={setIsEditProfile} /></div>
                                {userInfo?.role === 'client' && (
                                    <div className='Profile__Body__JobSection'>
                                        <Jobs pastJobs={postedJobs} setAddNewJob={setAddNewJob} />
                                    </div>
                                )}
                            </>
                        )}
                </div>
            </div>
            <JobPostModalBody openJobPostModal={addNewJob} setAddNewJob={setAddNewJob} />
        </>
    );
}

export default Profile;