import React, { useEffect, useState } from 'react';
import logo from "../../../assets/logo.svg";
import { Outlet } from 'react-router-dom';
import './Layout.scss'

const RegistrationProtectedRoute = () => {

    return (
        <div className='Registration'>
            <div style={{ width: '1408px', height: '750px' }} className='Registration__Card'>
                <div className='Registration__Card__Header'>
                    <img src={logo} alt='Mercury logo' />
                </div>
                <div className='Registration__Card__Body'>
                    <Outlet />
                </div>
            </div>
        </div>
    );
}

export default RegistrationProtectedRoute;