import React, { useEffect, useState } from 'react';
import InputBox from '../../../../components/InputBox';
import { Input, Radio, Select, Typography } from 'antd';
import CustomModal from '../../../../components/CustomModal';
import CustomButton from '../../../../components/CustomButton';
import dollar from '../../../../assets/dollar.svg';
import './JobPostModalBody.scss'
import { getSkillsList, getToolsList } from '../../../../services/clientService';
import { getJobCreationErrorFields, tranformDropdownData, validateJobCreateDetails } from '../../../../utils/globalUtils';
import { createJob } from '../../../../services/jobService';

const { Title, Text } = Typography;
const { TextArea } = Input;

interface NewJobDetails {
    jobTitle: string
    jobDesc: string
    skills: string[] | []
    tools: string[] | []
    jobType: string
    minBudget: string
    maxBudget: string
    maxHours: string
    paymentRateType: string
    jobDuration: string
    jobDurationType: string
    minHourlyRate: string
    maxHourlyRate: string
    maxHoursPerWeek: string
}


interface IJobPostModalBodyProps {
    openJobPostModal: boolean
    setAddNewJob: React.Dispatch<React.SetStateAction<boolean>>
}

const defaultNewJobDetails = {
    jobTitle: '',
    jobDesc: '',
    skills: [],
    tools: [],
    jobType: 'onetime',
    minBudget: '',
    maxBudget: '',
    maxHours: '',
    paymentRateType: 'hourly',
    jobDuration: '',
    jobDurationType: 'hours',
    minHourlyRate: '',
    maxHourlyRate: '',
    maxHoursPerWeek: '',
};

const defaultNewJobValidationErrror  = {
    jobTitle: false,
    jobDesc: false,
    skills: false,
    tools: false,
    jobType: false,
    minBudget: false,
    maxBudget: false,
    maxHours: false,
    paymentRateType: false,
    jobDuration: false,
    jobDurationType: false,
    minHourlyRate: false,
    maxHourlyRate: false,
    maxHoursPerWeek: false
}

const JobPostModalBody = ({openJobPostModal, setAddNewJob}: IJobPostModalBodyProps) => {
    const [newJobDetails, setNewJobDetails] = useState<NewJobDetails>(defaultNewJobDetails);
    const [skillsList, setSkillsList] = useState([]);
    const [toolsList, setToolsList] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [errorFields, setErrorFields] = useState(defaultNewJobValidationErrror);

    const fetchSkillsList = async() => {
        const response = await getSkillsList();
        if(response?.data?.statusCode === 200) {
            const tranformedSkillsList = tranformDropdownData(response?.data?.data?.skills);
            setSkillsList(() => tranformedSkillsList)
        }
    };

    const fetchToolsList = async() => {
        const response = await getToolsList();
        if(response?.data?.statusCode === 200) {
            const tranformedToolsList = tranformDropdownData(response?.data?.data?.tools);
            setToolsList(tranformedToolsList)
        }
    };

    useEffect(() => {
        if(openJobPostModal) {
            fetchSkillsList();
            fetchToolsList()
        }
    }, [openJobPostModal]);

    const handleOnJobSave = async() => {
        setIsSubmitting(() => true);
        if(validateJobCreateDetails(newJobDetails)) {
            const response = await createJob({
                jobTitle: newJobDetails?.jobTitle,
                jobDesc: newJobDetails?.jobDesc,
                skills: newJobDetails?.skills,
                tools: newJobDetails?.tools,
                jobType: newJobDetails?.jobType,
                ...(newJobDetails?.jobType === 'ongoing' ? 
                    {paymentRateType: newJobDetails?.paymentRateType, minHourlyRate: newJobDetails?.minHourlyRate, maxHourlyRate: newJobDetails?.maxHourlyRate, maxHoursPerWeek: newJobDetails?.maxHoursPerWeek} 
                    : {minBudget: newJobDetails?.minBudget, maxBudget: newJobDetails?.maxBudget, jobDuration: newJobDetails?.jobDuration, jobDurationType: newJobDetails?.jobDurationType}
                )
            })
            if(response?.data?.statusCode === 200 || response?.data?.statusCode === 201) {
                setIsSubmitting(() => false);
                setNewJobDetails(() => defaultNewJobDetails);
                setAddNewJob(false)
            } else {
                setIsSubmitting(() => false);
            }
        } else {
            const updatedErrorFields = getJobCreationErrorFields(newJobDetails);
            setErrorFields({...errorFields, ...updatedErrorFields});
            setIsSubmitting(() => false);
        }
        
    }

    const modalBody = (
        <div className='AddNewJobModalBody'>
            <div className='AddNewJobModalBody__JobTitle'>
                    <InputBox label='Job Title' placeholder='Add a Title' value={newJobDetails?.jobTitle} status={errorFields?.jobTitle ? 'error' : ''} errorMessage={'Please enter job title'} onChange={(event) => setNewJobDetails({...newJobDetails, jobTitle: event.target.value})} />
            </div>
            <div className='AddNewJobModalBody__JobDetails'>
                <Title className='AddNewJobModalBody__JobDetails__Title' level={5}>Job details</Title>
                <TextArea 
                    className='AddNewJobModalBody__JobDetails__TextArea'
                    value={newJobDetails?.jobDesc} 
                    rows={2}
                    variant='filled'
                    placeholder='A description helps freelances better understand the scope and requirements of your job. This is also a great place to include key deliverables, links or references.'
                    onChange={(event) => setNewJobDetails({...newJobDetails, jobDesc: event.target.value})}
                    status={errorFields?.jobDesc ? 'error' : ''}
                />
                {errorFields?.jobDesc && <Text type='danger'>Please enter job description</Text>}
            </div>
            <div className='AddNewJobModalBody__Technologies'>
                <div className='AddNewJobModalBody__Technologies__Skills'>
                    <Title className='AddNewJobModalBody__Technologies__Skills__Title' level={5}>Skills</Title>
                    <Select
                        mode="multiple"
                        placeholder="Please select"
                        onChange={(value) => setNewJobDetails({...newJobDetails, skills: value})}
                        options={skillsList}
                        style={{width: '100%'}}
                        status={errorFields?.skills ? 'error' : ''}
                    />
                    {errorFields?.skills && <Text type='danger'>Please select skills</Text>}
                </div>
                <div className='AddNewJobModalBody__Technologies__Tools'>
                    <Title className="AddNewJobModalBody__Technologies__Tools__Title" level={5}>Tools</Title>
                    <Select
                        mode="multiple"
                        placeholder="Please select"
                        onChange={(value) => setNewJobDetails({...newJobDetails, tools: value})}
                        options={toolsList}
                        style={{width: '100%'}}
                        status={errorFields?.tools ? 'error' : ''}
                    />
                    {errorFields?.tools && <Text type='danger'>Please select tools</Text>}
                </div>
            </div>
            <div className='AddNewJobModalBody__AdditionalInfo'>
            <div className='AddNewJobModalBody__AdditionalInfo__JobType'>
                <Title level={5}>Type of Job</Title>
                <Radio.Group defaultValue={newJobDetails?.jobType} className='AddNewJobModalBody__AdditionalInfo__JobType__RadioGroup' onChange={(event) => setNewJobDetails({...newJobDetails, jobType: event?.target?.value})}>
                <Radio value='onetime' className='AddNewJobModalBody__AdditionalInfo__JobType__RadioGroup__Option'>One Time</Radio>
                <Radio value='ongoing' className='AddNewJobModalBody__AdditionalInfo__JobType__RadioGroup__Option'>On-going</Radio>
                </Radio.Group>
            </div>
                {newJobDetails?.jobType === 'onetime' ? (
                    <>
                        <div className='AddNewJobModalBody__AdditionalInfo__OneTimeBudget'>
                            <Title level={5}>Budget</Title>
                            <div className='AddNewJobModalBody__AdditionalInfo__OneTimeBudget__Inputs'>
                                <InputBox type='number' prefix={<img src={dollar} alt={'dollar'}/>} status={errorFields?.minBudget ? 'error' : ''} errorMessage={'Please enter min budget'} placeholder='Min. Budget' value={newJobDetails?.minBudget} onNumberInputChange={(value) => setNewJobDetails({...newJobDetails, minBudget: value || ''})} />
                                <InputBox type='number' prefix={<img src={dollar} alt={'dollar'}/>} status={errorFields?.maxBudget ? 'error' : ''} errorMessage={'Please enter max budget'} placeholder='Max. Budget' value={newJobDetails?.maxBudget} onNumberInputChange={(value) => setNewJobDetails({...newJobDetails, maxBudget: value || ''})} />
                            </div>
                        </div>
                        <div className='AddNewJobModalBody__AdditionalInfo__OneTimeDuration'>
                            <Title level={5}>Job duration</Title>
                            <div className='AddNewJobModalBody__AdditionalInfo__OneTimeDuration__Inputs'>
                                <InputBox type='number' value={newJobDetails?.jobDuration} status={errorFields?.jobDuration ? 'error' : ''} errorMessage={'Please enter job duration'} onNumberInputChange={(value) => setNewJobDetails({...newJobDetails, jobDuration: value || ''})}/>
                                <Select
                                    style={{width: '30%'}} 
                                    options={[
                                        { value: 'hours', label: 'Hours' },
                                        { value: 'days', label: 'Days' },
                                        { value: 'weeks', label: 'Weeks' },
                                        { value: 'months', label: 'Months' },
                                    ]}
                                    onChange={(value: string) => setNewJobDetails({...newJobDetails, jobDurationType: value})}
                                    placeholder='Duration type'
                                    value={newJobDetails?.jobDurationType}
                                />
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div className='AddNewJobModalBody__AdditionalInfo__OnGoingPayout'>
                            <Title level={5}>How do you want to pay for this job?</Title>
                            <Radio.Group value={newJobDetails?.paymentRateType} className='AddNewJobModalBody__AdditionalInfo__OnGoingPayout__RadioGroup' onChange={(event) => setNewJobDetails({...newJobDetails, paymentRateType: event?.target?.value})}>
                                <Radio value='hourly' className='AddNewJobModalBody__AdditionalInfo__OnGoingPayout__RadioGroup__Option'>Hourly</Radio>
                                <Radio value='weekly' className='AddNewJobModalBody__AdditionalInfo__OnGoingPayout__RadioGroup__Option'>Weekly</Radio>
                                <Radio value='monthly' className='AddNewJobModalBody__AdditionalInfo__OnGoingPayout__RadioGroup__Option'>Monthly</Radio>
                            </Radio.Group>
                        </div>
                        <div className='AddNewJobModalBody__AdditionalInfo__OnGoingBudget'>
                            <InputBox className='AddNewJobModalBody__AdditionalInfo__OnGoingBudget__MinRate' status={errorFields?.minHourlyRate ? 'error' : ''} errorMessage={'Please enter min hourly rate'} label='Min. hourly rate' type='number' prefix={<img src={dollar} alt={'dollar'}/>} placeholder='Min. hourly rate' value={newJobDetails?.minHourlyRate} onNumberInputChange={(value) => setNewJobDetails({...newJobDetails, minHourlyRate: value || ''})} />
                            <InputBox className='AddNewJobModalBody__AdditionalInfo__OnGoingBudget__MaxRate' status={errorFields?.maxHourlyRate ? 'error' : ''} errorMessage={'Please enter max hourly rate'} label='Max. hourly rate' type='number' prefix={<img src={dollar} alt={'dollar'}/>} placeholder='Max. hourly rate' value={newJobDetails?.maxHourlyRate} onNumberInputChange={(value) => setNewJobDetails({...newJobDetails, maxHourlyRate: value || ''})} />
                            <div className='AddNewJobModalBody__AdditionalInfo__OnGoingBudget__Hours'>
                                <InputBox type='number' label={'Max. hours per week'} status={errorFields?.maxHoursPerWeek ? 'error' : ''} errorMessage={'Please enter max hrs per week'} value={newJobDetails?.maxHoursPerWeek} onNumberInputChange={(value) => setNewJobDetails({...newJobDetails, maxHoursPerWeek: value || ''})}/>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );

    return (
        <CustomModal isOpen={openJobPostModal} onCancel={() => setAddNewJob(false)} closable={false} width={'900px'} footer={[
            <CustomButton key='cancel' onClick={() => setAddNewJob(false)} buttonText='Cancel' />,
            <CustomButton key='save' onClick={handleOnJobSave} buttonText='Save' loading={isSubmitting} />
        ]}>
            {modalBody}
        </CustomModal>
    );
}

export default JobPostModalBody;