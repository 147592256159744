import axiosInstance from "./axiosConfig";

export const getFreelancerServices = async () => {
    try {
        const response = await axiosInstance.get('services/categories');
        return response;
    } catch(err) {
        console.log('>>>> getFreelancerServices >>> ERROR-> ', err);
    }
};

export const getQuestions = async (serviceId: string) => {
    try {
        const response = await axiosInstance.get(`services/questions?serviceId=${serviceId}`);
        return response;
    } catch(err) {
        console.log('>>>> getQuestions >>> ERROR-> ', err);
    }
};

type AnswerType = {
    [key: string]: any
}

export const saveFreelancerAnswers = async (requestData: {
    serviceId: string,
    answers: AnswerType
}) => {
    try {
        const response = await axiosInstance.post('services/save-answer', requestData);
        return response;
    } catch(err) {
        console.log('>>>> saveFreelancerAnswers >>> ERROR-> ', err);
    }
}