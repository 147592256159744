import { getClientDataPacket, getfreelancerDataPacket } from "../utils/globalUtils";
import axiosInstance from "./axiosConfig";

export const registerUser = async (requestData: {
    [key: string]: string;
    role: string;
  }) => {
    try {
        const response = await axiosInstance.post('/auth/register', requestData);
        return response;
    } catch(err) {
        console.log('>>>>>>> ERROR-> ', err);
        throw err;
    }
};

export const verifyOtp = async (requestData: {
  [key: string]: string;
  otp: string;
}) => {
  try {
      const response = await axiosInstance.post('/auth/verify-otp', requestData);
      localStorage.setItem('userDetails', JSON.stringify(response?.data?.data));
      return response;
  } catch(err) {
      console.log('>>>>>>> ERROR-> ', err);
  }
};

export const setupUserPassword = async (requestData: {
  password: string
}) => {
  try {
      const response = await axiosInstance.post('auth/setup-password', requestData);
      return response;
  } catch(err) {
      console.log('>>>>>>> ERROR-> ', err);
  }
};

export const postFreelancerDetails = async (requestData: {
  fullName: string
  userName: string
  phoneNumber: string
  dateOfBirth: string
  about: string
  location: string
  professionalTitle: string
  serviceId: string
  openingHrs: string
  closingHrs: string
  portfolioUrl1: string
  portfolioUrl2: string
}) => {
  try {
      const requestPacket = getfreelancerDataPacket(requestData);
      const response = await axiosInstance.post('freelancer/onboarding', requestPacket);
      return response;
  } catch(err) {
      console.log('>>>>>>> ERROR-> ', err);
  }
};

export const postClientDetails = async (requestData: {
  fullName: string
  userName: string;
  phoneNumber: string
  dateOfBirth: string
  about: string
  location: string
}) => {
  try {
      const requestPacket = getClientDataPacket(requestData);
      const response = await axiosInstance.post('client/enrollment', requestPacket);
      return response;
  } catch(err) {
      console.log('>>>> postFreelancerDetails >>> ERROR-> ', err);
  }
};

export const postProfilePicture = async (requestData: {
  profileImageUrl: string
}) => {
  try {
    const response = await axiosInstance.post('common/profile', requestData);
    return response;
  } catch(err) {
    console.log('>>>>> postProfilePicture >>>>> ERROR-> ', err);
  }
}

export const uploadDocument = async(requestData: {
  type: string,
  file: string
}) => {
  try {
    const response = await axiosInstance.post('common/upload', requestData);
    return response;
  } catch(err) {
    console.log('>>>>> uploadDocument >>>>> ERROR-> ', err);
  }
}

export const saveVerifyDocument = async(requestData: {
  type: string,
  fileUrl: string
}) => {
  try {
    const response = await axiosInstance.post('common/user-verification', requestData);
    return response;
  } catch(err) {
    console.log('>>>>> verifyIdentity >>>>> ERROR-> ', err);
  }
}

export const loginUser = async(requestData: {
  email: string
  password: string
}) => {
  try {
    const response = await axiosInstance.post('auth/login', requestData);
    return response;
  } catch(err) {
    console.log('>>>>> loginUser >>>>> ERROR-> ', err);
  }
}