import React, { useEffect, useRef, useState } from 'react';
import CustomButton from '../../components/CustomButton';
import CONSTANTS from './constants';
import './Questionnaire.scss'
import { Link, useNavigate } from 'react-router-dom';
import { getQuestions, saveFreelancerAnswers } from '../../services/freelancerService';
import Question from '../../components/Question';
import { tranformAnswersPayload, tranformQuestionsData } from '../../utils/globalUtils';
import { Spin } from 'antd';

type userAnswersType = {
    [key: string]: any
}


const contentStyle: React.CSSProperties = {
    padding: 50,
    background: 'rgba(0, 0, 0, 0.05)',
    borderRadius: 4,
};

const Questionnaire = () => {

    const navigate = useNavigate();

    const { NEXT, ALREADY_HAVE_ACCOUNT, GOTO_DASHBOARD } = CONSTANTS;
    const dataFetchedRef = useRef(false);
    const [currentScreen, setCurrentScreen] = useState<string>('questions');
    const [currentScreenIndex, setCurrentScreenIndex] = useState<number>(0);
    const [serviceName, setServiceName] = useState<string>('');
    const [questions, setQuestions] = useState([]);
    const [userAnswers, setUserAnswers] = useState<userAnswersType>({});
    const [loading, setLoading] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const serviceId = localStorage.getItem('serviceId') || '';

    const retrieveQuestions = async() => {
        // const response = await getQuestions('6f2b1d8e-4c7a-5c3e-9d2b-1a4e9f7c5d3e');
        const response = await getQuestions(serviceId);
        if(response?.data?.statusCode === 200) {
            const tranformedData = tranformQuestionsData(response?.data?.data?.questions, 'questionId');
            setServiceName(() => response?.data?.data?.label);
            setQuestions(tranformedData);
            setLoading(() => false);
        } else {
            setLoading(() => false);
            setQuestions([]);
        }
    }

    const saveAnswers = async() => {
        const response = await saveFreelancerAnswers({
            serviceId: serviceId,
            answers: tranformAnswersPayload(userAnswers)
        });
        if(response?.data?.statusCode === 200 || response?.data?.statusCode === 201) {
            setIsSubmitting(() => false);
            navigate('/upload-picture', { replace: true});
        } else {
            setIsSubmitting(() => false);
            setCurrentScreenIndex(currentScreenIndex - 1);
        }
    }

    useEffect(() => {
        if (dataFetchedRef.current) return;
        setLoading(() => true);
        dataFetchedRef.current = true;
        retrieveQuestions();
    }, [])

    useEffect(() => {
        switch(currentScreenIndex) {
            case 0:
                setCurrentScreen('questions');
                break;
            case 1:
                setIsSubmitting(() => true);
                saveAnswers()
                break;
            default:
                setCurrentScreen('questions');
        }

    }, [currentScreenIndex]);

    const handleAnswersChange = (key: string, value: string | string[], type: string, questionId: string) => {
        if(type === 'range') {
            setUserAnswers({...userAnswers, [questionId]: {
                ...userAnswers[questionId],
                [key]: value
            }})
        } else {
            setUserAnswers({...userAnswers, [questionId]: value})
        }
    }

    const questionsContainer = (
        <div className='QuestionsContainer'>
            {!!questions?.length && questions?.map(({label, questionId, variant, options}) => (
                <Question id={questionId} key={questionId} label={label} variant={variant} value={userAnswers[questionId]} options={options} onChange={(key, value) => handleAnswersChange(key, value, variant, questionId)} />
            ))}
        </div>
    )

    const content = <div style={contentStyle} />;

    if(loading) {
        return (
            <Spin tip="Loading">
                {content}
            </Spin>
        )
    }

    return (
        <>
            <div className='Registration__Card__Body__MainContent'>
                <div className='Registration__Card__Body__MainContent__SubHeader'>
                    {currentScreen === 'questions' && <>{serviceName}</>}
                </div>
                <div className='Registration__Card__Body__MainContent__Body'>
                    {currentScreen === 'questions' && (<>{questionsContainer}</>)}
                </div>
                <div className='Registration__Card__Body__MainContent__Footer'>
                    <CustomButton className='Registration__Card__Body__MainContent__Footer__NextBtn' onClick={(event) => setCurrentScreenIndex(currentScreenIndex + 1)} buttonText={NEXT} loading={isSubmitting} />
                </div>
            </div>
            <div className='Registration__Card__Body__Footer'>{ALREADY_HAVE_ACCOUNT} <Link to={'/login'} className='Registration__Card__Body__Footer__GotoDashboard'>{GOTO_DASHBOARD}</Link></div>
        </>
    );
}

export default Questionnaire;