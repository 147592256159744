import React, { useEffect, useRef, useState } from 'react';
import { Segmented, Drawer } from 'antd';
import chat from '../../assets/chat.svg';
import notifications from '../../assets/notifications.svg';
import user from '../../assets/user.png';
import './FreelancerApplications.scss';
import CustomButton from '../../components/CustomButton';
import InputBox from '../../components/InputBox';
import { acceptApplication, getApplicationsByJobId, getJobsList, jobApply } from '../../services/jobService';
import JobDetails from '../../components/JobDetails';
import { useNavigate, useParams } from 'react-router-dom';
import FreelancerTile from '../../components/FreelancerTile';
import { useAppDispatch } from '../../hooks/reduxHooks';
import { setSelectedApplication } from '../../slice/clientSlice';
import FreelancerOverview from '../FreelancerOverview';

const DATA = [
    {
        id: "1",
        name: 'Ronald Richard',
        imageUrl: user,
        professionalTitle: 'Photographer',
        rating: '4.5',
        jobTitles: ['Product Designer'],
        minRate: '70',
        maxRate: '100'
    },
    {
        id: "2",
        name: 'Ronald Richard',
        imageUrl: user,
        professionalTitle: 'Photographer',
        rating: '4.5',
        jobTitles: ['Product Designer'],
        minRate: '70',
        maxRate: '100'
    },
    {
        id: "3",
        name: 'Ronald Richard',
        imageUrl: user,
        professionalTitle: 'Photographer',
        rating: '4.5',
        jobTitles: ['Product Designer'],
        minRate: '70',
        maxRate: '100'
    },
    {
        id: "4",
        name: 'Ronald Richard',
        imageUrl: user,
        professionalTitle: 'Photographer',
        rating: '4.5',
        jobTitles: ['Product Designer'],
        minRate: '70',
        maxRate: '100'
    }
];

const FreelancerApplications = () => {

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [applicationsList, setApplicationsList] = useState([]);
    const [openDrawer, setOpenDrawer] = useState(false);
    const dataFetchedRef = useRef(false);
    const { projectId } = useParams();
    const [isAccepting, setIsAccepting] = useState(false);

    const fetchFreelancersListByJobId = async() => {
        if(projectId) {
            const response = await getApplicationsByJobId(projectId);
            if(response?.data?.statusCode === 200) {
                setApplicationsList(response?.data?.data?.applications);
            } else {
                setApplicationsList([]);
            }
        } else {
            setApplicationsList([]);
        }
    }

    const onDrawerClose = () => {
        setOpenDrawer(false);
    };

    useEffect(() => {
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        fetchFreelancersListByJobId();
    }, []);

    const handleOnViewApplication = (freelancerId: any) => {
        const selectedApplication = applicationsList?.find((application: any) => application?.freelancerId === freelancerId)
        dispatch(setSelectedApplication({application: selectedApplication}));
        setOpenDrawer(() => true);
    }

    const handleAcceptApplication = async (applicationId: string) => {
        setIsAccepting(() => true);
        const response = await acceptApplication({applicationId: applicationId});
        if(response?.data?.statusCode === 201) {
            setOpenDrawer(() => false);
            setIsAccepting(() => false);
            navigate(-1);
        } else {
            setIsAccepting(() => false);
        }
    }

    return (
        <div className='FreelancerApplicationsContainer'>
            <div className='FreelancerApplicationsContainer__Header'>
                <div className='FreelancerApplicationsContainer__Header__Title'>Applications</div>
                <div className='FreelancerApplicationsContainer__Header__Actions'>
                    <CustomButton buttonText='Upgrade' onClick={() => {}} />
                    <img src={chat} alt='chat' />
                    <img src={notifications} alt='notifications' />
                </div>
            </div>
            <div className='FreelancerApplicationsContainer__Body'>
                <div className='FreelancerApplicationsContainer__Body__Filters'>
                    <InputBox placeholder='Search for application' onChange={() => {}} />
                    <CustomButton buttonText='Back' onClick={() => navigate(-1)} />
                </div>
                <div className='FreelancerApplicationsContainer__Body__ApplicationsList'>
                    {applicationsList?.map((application: any) => (
                        <FreelancerTile
                            key={application?.freelancerId} 
                            freelancerId={application?.freelancerId}
                            userName={`${application?.freelancer?.firstName} ${application?.freelancer?.lastName}`} 
                            imageUrl={application?.freelancer?.profileImageUrl || ''} 
                            professionalTitle={application?.freelancer?.professionalTitle} 
                            rating={application?.freelancer?.rating} 
                            jobTitles={[application?.freelancer?.service]} 
                            showBudget={false}
                            button1Text='View application'
                            onButton1Click={(freelancerId) => handleOnViewApplication(freelancerId)}
                        />
                    ))}
                </div>
            </div>
            <Drawer
                closable={false}
                onClose={onDrawerClose}
                open={openDrawer}
                size={'large'}
            >
                <FreelancerOverview isAccepting={isAccepting} onClose={() => setOpenDrawer(false)} onAccept={handleAcceptApplication} />
            </Drawer>
        </div>
    );
};

export default FreelancerApplications;