import React, { useEffect, useRef, useState } from 'react';
import { Segmented, Drawer } from 'antd';
import chat from '../../assets/chat.svg';
import notifications from '../../assets/notifications.svg';
import './ClientProjects.scss';
import CustomButton from '../../components/CustomButton';
import InputBox from '../../components/InputBox';
import { getClientPostedJobs, getJobsList, jobApply } from '../../services/jobService';
import JobDetails from '../../components/JobDetails';
import { useNavigate } from 'react-router-dom';

const ClientProjects = () => {

    const navigate = useNavigate();
    const [jobsList, setJobsList] = useState([]);
    const dataFetchedRef = useRef(false);

    const fetchProjects = async() => {
        const response = await getClientPostedJobs();
        if(response?.data?.statusCode === 200) {
            setJobsList(response?.data?.data?.jobs);
        } else {
            setJobsList([]);
        }
    }

    useEffect(() => {
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        fetchProjects();
    }, []);

    const handleOnViewApplication = (jobId: string) => {
        navigate(`/client-projects/${jobId}`);
    }

    return (
        <div className='ClientProjectsContainer'>
            <div className='ClientProjectsContainer__Header'>
                <div className='ClientProjectsContainer__Header__Title'>My Jobs</div>
                <div className='ClientProjectsContainer__Header__Actions'>
                    <CustomButton buttonText='Upgrade' onClick={() => {}} />
                    <img src={chat} alt='chat' />
                    <img src={notifications} alt='notifications' />
                </div>
            </div>
            <div className='ClientProjectsContainer__Body'>
                {jobsList?.map((job: any) => (
                    <JobDetails 
                        userRole={'client'}
                        jobId={job?.jobId}
                        clientName={`${job?.client?.firstName} ${job?.client?.lastName}`}
                        posterImageUrl={job?.client?.profileImageUrl}
                        jobDurationLabel={job?.jobDurationLabel}
                        hiringType={job?.jobType}
                        jobTitle={job?.jobTitle}
                        minBudget={job?.jobType === 'onetime' ? job?.minBudget : job?.minHourlyRate}
                        maxBudget={job?.jobType === 'onetime' ? job?.maxBudget : job?.maxHourlyRate}
                        jobType={job?.jobType}
                        duration={job?.maxHoursPerWeek}
                        postedDate={job?.createdAt}
                        skills={job?.skills}
                        key={job?.jobId}
                        showClientImage={false}
                        showClientName={false}
                        showViewApplicationsButton={job?.jobState !== 'closed'}
                        applicationsCount={job?.appliedCount?.toString()}
                        onViewApplicationsClick={(jobId) => handleOnViewApplication(jobId)}
                    />
                ))}
            </div>
        </div>
    );
};

export default ClientProjects;