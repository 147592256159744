import React, { useEffect, useState } from 'react';
import { Input, Typography } from 'antd';
import InputBox from '../../components/InputBox';
import attachment from '../../assets/attachment.svg';
import './JobApply.scss';
import CustomButton from '../../components/CustomButton';
import ProjectsList from '../ProjectsList';
import project1 from '../../assets/project1.png';
import project2 from '../../assets/project2.png';
import ProjectCard from '../../components/ProjectCard';
import { useAppSelector } from '../../hooks/reduxHooks';
import { jobApply } from '../../services/jobService';

const { Title, Text } = Typography;
const { TextArea } = Input;

interface IJobApplyProps {
    onAfterSubmit: () => {}
    onClose: () => void
}

const projectsData = [
    {
        id: '1',
        title: 'Essence of happiness',
        imageUrl: project1
    },
    {
        id: '2',
        title: 'Urban Symphony',
        imageUrl: project2
    },
    {
        id: '3',
        title: 'Essence of happiness',
        imageUrl: project1
    },
    {
        id: '4',
        title: 'Urban Symphony',
        imageUrl: project2
    },
    {
        id: '5',
        title: 'Essence of happiness',
        imageUrl: project1
    }
]

const DURATION = {
    'ongoing': 'Ongoing',
    'onetime': 'One time'
}

const JobApply = ({onAfterSubmit, onClose}: IJobApplyProps) => {
    const selectedJob = useAppSelector(state => state?.freelancer?.selectedJob);

    const [showProjectSelectionScreen, setShowProjectSelectionScreen] = useState(false);
    const [linkedProjects, setLinkedProjects] = useState<string[]>([]);
    const [jobDescription, setJobDescription] = useState('');
    const [meetLink, setMeetLink] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleOnClose = () => {
        setShowProjectSelectionScreen(() => false);
        setJobDescription(() => '');
        setMeetLink(() => '');
        setLinkedProjects(() => []);
        onClose();
    }

    const handleOnJobApply = async (payload: {
        jobId: string
        description: string
        meetUrl: string
        projects: string[] | []
    }) => {
        setIsSubmitting(() => true);
        const response = await jobApply(payload);
        if(response?.data?.statusCode === 200 || response?.data?.statusCode === 201) {
            setIsSubmitting(() => false);
            handleOnClose();
            onAfterSubmit();
        } else {
            setIsSubmitting(() => false);
            handleOnClose();
        }
    }

    if(showProjectSelectionScreen) {
        return <ProjectsList projects={projectsData} onBackBtnClick={() => setShowProjectSelectionScreen(() => false)} onLinkBtnClick={(selectedProjectsList) => {
            setLinkedProjects(selectedProjectsList)
            setShowProjectSelectionScreen(() => false)
        }}/>
    }

    return (
        <div className='JobApplyContainer'>
            <div className='JobApplyContainer__FirstRow'>
                <div className='JobApplyContainer__FirstRow__ClientImage'><img src={selectedJob?.client?.profileImageUrl} alt={selectedJob?.client?.firstName} /></div>
                <div className='JobApplyContainer__FirstRow__Details'>
                    <div className='JobApplyContainer__FirstRow__Details__ClientName'>{selectedJob?.client?.firstName} {selectedJob?.client?.lastName}</div>
                    <div className='JobApplyContainer__FirstRow__Details__JobTitle'>{selectedJob?.jobTitle}</div>
                </div>
            </div>
            <div className='JobApplyContainer__SecondRow'>
                <div className='JobApplyContainer__SecondRow__Tile'>{selectedJob?.jobType === 'ongoing' ? `$${selectedJob?.minHourlyRate} - $${selectedJob?.maxHourlyRate}/hr`: `$${selectedJob?.minBudget} - $${selectedJob?.maxBudget}`}</div>
                <div className='JobApplyContainer__SecondRow__Tile'>{selectedJob?.jobDurationLabel}</div>
                <div className='JobApplyContainer__SecondRow__Tile'>Duration: {DURATION[selectedJob?.jobType as keyof typeof DURATION]}</div>
            </div>
            <div className='JobApplyContainer__ThirdRow'>
                <Title className='JobApplyContainer__ThirdRow__Title' level={5}>Explain why you should be hired</Title>
                <TextArea 
                    className='JobApplyContainer__ThirdRow__TextArea'
                    value={jobDescription} 
                    rows={4}
                    variant='filled'
                    placeholder='An explanation helps clients better in hiring you for this job'
                    onChange={(event) => setJobDescription(event?.target?.value)}
                />
            </div>
            <div className='JobApplyContainer__FourthRow'>
                <InputBox label='Meeting link to set up a call hassle-free' suffix={<img src={attachment} alt={'link'}/>} onChange={(event) => setMeetLink(event?.target?.value)} value={meetLink} placeholder='Add link' />
            </div>
            <div className='JobApplyContainer__FifthRow'>
                <div className='JobApplyContainer__FifthRow__Header'>
                    <Title level={5}>Link example projects</Title>
                    <Text className='JobApplyContainer__FifthRow__Header__Action'>Optional</Text>
                </div>
                <Text className='JobApplyContainer__FifthRow__SubHeader'>Applications with at least 1 linked project are 50% more likely to be viewed.</Text>
                {!linkedProjects?.length ? (
                    <CustomButton buttonText='Select projects' onClick={() => setShowProjectSelectionScreen(true)} />
                ) : (
                    <div className='JobApplyContainer__FifthRow__LinkedProjects'>
                        {linkedProjects?.map((projectId) => {
                            const projectInfo = projectsData?.find(project => project?.id === projectId);
                            return <ProjectCard key={projectInfo?.id} id={projectInfo?.id || ''} title={projectInfo?.title || ''} imageUrl={projectInfo?.imageUrl || ''} />
                    })}
                    </div>
                )}
            </div>
            <div className='JobApplyContainer__SixthRow'>
                <CustomButton buttonText='Cancel' onClick={handleOnClose} />
                <CustomButton buttonText='Submit Application' onClick={() => handleOnJobApply({jobId: selectedJob?.jobId, description: jobDescription, meetUrl: meetLink, projects: linkedProjects})} loading={isSubmitting} />
            </div>
        </div>
    );
};

export default JobApply;